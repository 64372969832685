import React, { useContext } from 'react'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const FooterEmail = () => {
    const [options] = useContext(OptionsContext)

    return (
        <footer className="c-section c-section--sm c-footer-email">
            <div className="container-lg">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
                        <StaticImage
                            src={'../../../images/pl-vertical-white.png'}
                            width={125}
                            alt={`${options.siteData.title} logo`}
                            className={'c-footer-email__logo'}
                        />

                        <p>
                            &copy; Protect Line 2010 - 2022 The guidance provided within this email is subject to the UK
                            regulatory regime and is therefore primarily targeted at consumers based in the UK.
                        </p>

                        <p>
                            Protect Line Ltd is an insurance broker and not an insurer. The service is 'fee-free' in
                            that there are no upfront or separate charges for consultations, quotes and discussions with
                            a Protect Line specialist. The cost of setting up the policy will be included in the
                            premiums you pay. Protect Line will not bill you separately for any guidance you receive
                            from one of our specialists.
                        </p>

                        <p>
                            Protect Line Ltd is authorised and regulated by the Financial Conduct Authority. Protect
                            Line Ltd is entered on the Financial Services Register (
                            <a
                                href="https://email.protectline.co.uk/emails/click/IgFpNrv42rUuusxOnA9wopfxL632pZneVn9jbOyFwQw1/g2JEEQx7p_R6GSGMnW4wJ7St8C4Pjem_foe_1ln3DvU1?track=false"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                            >
                                www.fca.org.uk/register
                            </a>
                            ) under reference 942467.
                        </p>

                        <p>Protect Line Ltd is registered in England and Wales registered no. 7059779</p>

                        <p>Registered address: Protect Line Ltd, Bourne Gate, Bourne Valley Road, Poole, BH12 1DY.</p>

                        <p>
                            Opening Hours: <br />
                            Monday to Thursday: 9:30-18:30
                            <br />
                            Friday: 9:30 - 18:00
                            <br />
                            Weekends &amp; Bank Holidays: Closed
                        </p>

                        <p>Contact Number: 0333 880 3030</p>

                        <p>
                            If you'd rather not receive emails from us, you can{' '}
                            <a href="https://email.protectline.co.uk/emails/unsubscribe/1960888023/joe.ellistone@protectline.co.uk?track=false">
                                click here to unsubscribe
                            </a>{' '}
                            from marketing emails - we'll still email you regarding the status of your application.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterEmail
